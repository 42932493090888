<template>
    <div class="modal fade" id="create-room" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <div class="media flex-fill">
                        <div class="icon-shape rounded-lg bg-primary text-white mr-5">
                            <i class="fe-zap"></i>
                        </div>
                        <div class="media-body align-self-center">
                            <h5 class="modal-title">Create Room</h5>
                            <p class="small">Create a Sprint Room to write with friends</p>
                        </div>
                    </div>

                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

            <ValidationObserver ref="form" v-slot="{ passes }">
                <form @submit.prevent="passes(submit)">
                    <div class="modal-body">
                        
                            <ValidationProvider name="name" rules="required|min:3|max:200" v-slot="{ errors }">
                                <div class="form-group">
                                    <label class="small" for="room-name">Name</label>
                                    <input class="form-control form-control-lg" name="room_name" id="room-name" :class="[{'is-invalid': errors.length}]" type="text" placeholder="Room Name" v-model.trim="form.name">
                                    <div class="invalid-feedback" v-for="(error, index) in errors" :key="'name' + index">
                                        {{ error }}
                                    </div>
                                </div>
                            </ValidationProvider>

                    </div>

                    <div class="modal-footer">
                        <button type="submit" class="btn btn-lg btn-block btn-primary d-flex align-items-center" :class="[{'disabled': sending}]" disable="sending">
                            Create Room
                            <div v-if="sending" class="spinner-border text-light spinner-border-sm ml-2" role="status"></div>
                            <i class="fe-arrow-right ml-auto"></i>
                        </button>
                    </div>
                </form>

                </ValidationObserver>

            </div>
        </div>
    </div>
</template>


<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { isError422 } from "@/utils/response";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min, max } from 'vee-validate/dist/rules';
import axios from 'axios'

extend('required', {
  ...required,
  message: 'Required'
});
extend('max', {
    ...max,
    message: 'Cannot be longer than 200 characters'
});

export default 
{
    data() {
        return {
            form: {
                name: '',
                default_timer_length: 25,
            },
            sending: false,
            error: false,
        }
    },

    components: {
        ValidationProvider,
        ValidationObserver
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
            
        })
    },

    methods: {
        async submit() {
            this.sending = true;
            this.error = false;

            await axios.post('/rooms', this.form).then((res) => 
            {
                $('#create-room').modal('hide')
                // Redirect to new room 
                this.$router.replace({ name: 'roomsView', params: res.data.data })
            })
            .catch((err) => 
            {
                if (isError422(err)) {
                    const errors = err.response.data.errors;
                    this.$refs.form.setErrors(errors);
                    return;
                }
                Vue.$toast.error('Ooops! Something wrong happened. Please try again later')
                this.error = true;
            })
            .then(() => {
                this.sending = false;
            });
        }
    },


}
</script>